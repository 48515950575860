.about-page {
  min-height: 100vh; /* Высота на весь экран */
  width: auto;
  color: black; /* Черный текст */
}

.content {
  width: 75%;
  height: 100%;
  border: 2px solid blue; /* Синяя рамка */
  padding: 20px;
  background-color: rgba(247, 247, 247, 0.753); /* Немного прозрачный белый фон для текста */
  margin: auto;
}

.responsive-video {
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* Отступ снизу для видео */
}

.video-title {
  margin-top: 20px; /* Отступ сверху для заголовка второго видео */
  margin-bottom: 20px; /* Отступ снизу для заголовка */
  text-align: center; /* Центрирование заголовка */
}
