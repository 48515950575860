.popup-container {
  display: flex;
  flex-direction: row; /* Измените на горизонтальное расположение */
  align-items: flex-start; /* Выравнивание элементов в начале контейнера */
  justify-content: flex-start; /* Выравнивание содержимого в начале контейнера */
  min-width: 400px;
  max-width: 600px; /* Увеличьте максимальную ширину */
  width: 100%;
}

/* Стили для основного содержимого попапа */
.popup-content {
  width: 70%; /* Основной контент занимает 70% ширины */
  overflow-y: auto; /* Добавьте прокрутку, если содержимое выходит за пределы */
  padding-right: 10px; /* Отступ, чтобы отделить от дополнительного блока */
  max-height: 300px;
}

/* Стили для дополнительного блока информации */
.popup-additional-info {
  width: 30%; /* Дополнительный контент занимает 30% ширины */
  overflow-y: auto; /* Добавьте прокрутку, если содержимое выходит за пределы */
  max-height: 300px;
}

.popup-container p {
    margin: 0;
}

.popup-container h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.popup-image {
    width: 166px;
    height: auto;
    margin: 10px auto; 
    display: block;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    min-width: 450px;
    max-width: 550px; 
    max-height: 350px;
    overflow-y: auto; 
}

.leaflet-popup-content-wrapper {
    overflow-y: hidden;
}

.popup-container .slick-prev:before,
.popup-container .slick-next:before {
  color: black; 
  opacity: 0.75;
}

.popup-container .slick-prev:hover,
.popup-container .slick-next:hover {
  color: black; 
  opacity: 0.5; 
}

.map-error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px; /* Можно настроить по вашему усмотрению */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Легкая прозрачность */
  border: 1px solid #ff0000; /* Красная граница для выделения */
  z-index: 1000; /* Убедитесь, что это значение выше, чем у карты */
  text-align: center; /* Текст по центру */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Тень для лучшей видимости */
  font-size: 1.2em; /* Больший размер текста */
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
  z-index: 999; /* Меньше, чем у сообщения об ошибке, но выше карты */
}

/* Стилизация скроллбара */
.popup-content::-webkit-scrollbar,
.popup-additional-info::-webkit-scrollbar {
  width: 3px; /* ширина скроллбара */
  height: 3px; /* высота скроллбара для горизонтального скроллинга, если он будет */
}

/* Стилизация дорожки (track) скроллбара */
.popup-content::-webkit-scrollbar-track,
.popup-additional-info::-webkit-scrollbar-track {
  background: #f1f1f1; /* цвет фона дорожки */
}

/* Стилизация ползунка (thumb) скроллбара */
.popup-content::-webkit-scrollbar-thumb,
.popup-additional-info::-webkit-scrollbar-thumb {
  background: #888; /* цвет ползунка */
}

/* Изменение цвета ползунка при наведении курсора */
.popup-content::-webkit-scrollbar-thumb:hover,
.popup-additional-info::-webkit-scrollbar-thumb:hover {
  background: #555; /* цвет ползунка при наведении */
}


.sample-link {
  cursor: pointer;
  color: #0000EE; /* Традиционный цвет ссылок */
  text-decoration: none; /* Убрать подчеркивание по умолчанию */
}

.sample-link:hover {
  text-decoration: underline; /* Добавить подчеркивание при наведении */
}

.sample-link:active {
  color: #551A8B; /* Изменить цвет при клике */
}

.popup-container .samples-header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 550px
}

@media (max-width: 700px) {

    .leaflet-popup-content-wrapper, .leaflet-popup-tip {
      min-width: 200px; 
    }
    .popup-container {
      min-width: 200px;
      max-width: 100%; /* popup-container теперь займет всю доступную ширину */
      flex-direction: row; /* Элементы остаются в строке */
      flex-wrap: wrap; /* Позволяем элементам переноситься на новую строку при необходимости */
    }
    .popup-image {
      width: auto;
      max-height: 100px;
      height: auto; 
    }

    .map-error-message {
      font-size: 1em; /* Меньший размер текста на мобильных устройствах */
      padding: 10px;
    }
  }

  @media screen and (max-height: 730px) {
    .leaflet-container { /* класс .leaflet-container используется для контейнера карты Leaflet */
      height: 450px
    }
  }