:root {
  --navbar-height-default: 106px;  /* Базовая высота navbar */
  --navbar-height-md: 91px;       /* Высота при ширине экрана от 993px до 1200px */
  --navbar-height-sm: 76px;       /* Высота при ширине экрана до 992px */
}


.navbar {
  background-image: linear-gradient(to right, #4a90e2, #343a40);
  padding: 15px 0; /* Увеличенный отступ */
}

#navbarNav {
  justify-content: center;
}

.navbar-brand {
  color: white;
  font-weight: bold;
  font-size: 34px !important; /* Увеличенный размер шрифта */
  margin-right: 25px; /* Увеличенный отступ */
}

.navbar-nav {
  align-items: center;
}

.nav-item {
  margin: 0 18px; /* Увеличенные отступы между элементами */
}

.nav-link {
  color: white;
  font-size: 28px !important;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #0a5c1b;
}

.nav-link:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

/* Общие стили для наведения и фокуса на .nav-link */
.nav-link:hover, .nav-link:focus, .nav-link.active  {
  background-color: rgba(210, 210, 221, 0.3);
  padding: 6px 12px; /* Увеличенные отступы */
  border-radius: 6px; /* Слегка увеличенный радиус скругления */
}

/* Специальные стили для .special-link при фокусе */
.special-link:focus {
  background-color: transparent; /* Убираем фон */
  padding: 0; /* Убираем дополнительные отступы */
  border-radius: 0; /* Убираем скругление */
}

/* Стили только для наведения на .special-link */
.special-link:hover {
  background-color: rgba(210, 210, 221, 0.3);
  padding: 6px 12px; /* Увеличенные отступы */
  border-radius: 6px; /* Слегка увеличенный радиус скругления */
}

.lab-name-and-logo:hover {
  box-shadow: 0 0 0 1px #4a90e2; 
  border-radius: 5px; 
  transform: scale(1.05);
  transition: transform 0.4s ease;
  /* transition: box-shadow 0.2s; */
} 

.lab-name-and-logo:active {
  transform: scale(1.0);
  transition: transform 0.3s;
}



.lab-name-and-logo {
  display: flex;
  align-items: center;
}

.borg-logo {
  height: 90px; /* Новый размер логотипа */
  margin-right: 25px; /* Увеличенный отступ */
}

.lab-name {
  color: white;
  font-weight: bold;
  font-size: 34px !important; /* Увеличенный размер шрифта */
  margin-right: 25px; /* Увеличенный отступ */
}

@media (min-width: 993px) and (max-width: 1200px) {
  .borg-logo {
    height: 75px; 
  }
  .navbar-brand, .lab-name {
    font-size: 27px !important;
  }
  .nav-link {
    font-size: 22px !important;
  } 
}

@media (max-width: 992px) { 
  .borg-logo {
    height: 60px; 
  }
  .navbar-brand, .lab-name {
    font-size: 20px !important;
  }
  .nav-link {
    font-size: 17px !important;
  } 
}

