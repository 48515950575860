.background-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height-default));
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/images/back.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.expedition-selector {
  width: calc(100vw / 3 - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  border: 2px solid rgba(255, 255, 255, 0.7); /* Светлая, полупрозрачная рамка */
  border-radius: 10px; /* Закругление углов */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Более заметная тень */
  position: relative;
  margin: 5px 20px;
  background-size: cover;
  background-position: center;
}

.expedition-selector:before {
  content: '';
  display: block;
  padding-top: 75%; /* Сохраняем пропорции 4:3 */
}

.expedition-selector:hover {
  transform: scale(1.03); /* Более заметный эффект при наведении */
  border-color: rgba(255, 255, 255, 0.9); /* Более яркая рамка при наведении */
}

.expedition-1 {
  background-image: url('/public/images/exp_1.jpg'); /* Фоновое изображение для Expedition 1 */
}

.expedition-2 {
  background-image: url('/public/images/exp_2.jpg'); /* Фоновое изображение для Expedition 2 */
}

.expedition-selector p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: clamp(20px, 3vw, 26px);
  text-align: center;
  padding: 10px;
  color: #ffffff; /* Белый текст */
}


@media (min-width: 993px) and (max-width: 1200px) {
  .background-container {
    min-height: calc(100vh - var(--navbar-height-md));
  }
  .expedition-selector {
    margin: 5px 10px; /* Меньшие отступы между окнами */
  }
}

@media (max-width: 992px) {
  .background-container {
    min-height: calc(100vh - var(--navbar-height-sm));
  }

  .background-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .expedition-selector {
    width: 55%; /* Ширина элемента для мобильных устройств */
    margin: 10px auto; /* Центрирование элемента */
    height: calc(55vw * (3 / 4)); /* Высота на основе ширины для поддержания пропорций 4:3 */
  }

  .expedition-selector:before {
    content: none; /* Убираем псевдо-элемент */
  }
}

@media (max-width: 600px) {
  .background-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .expedition-selector {
    width: 90%; /* Ширина элемента для мобильных устройств */
    margin: 10px auto; /* Центрирование элемента */
    height: calc(90vw * (3 / 4)); /* Высота на основе ширины для поддержания пропорций 4:3 */
  }

  .expedition-selector:before {
    content: none; /* Убираем псевдо-элемент */
  }
}
