/* galleryWindow.css */

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  line-height: 0px;
}


.gallery-window {
  flex: 0 0 auto; /* Не растягиваем и не сжимаем элементы */
  margin: 1px; /* добавляем небольшой отступ между элементами */
  box-sizing: border-box; /* Учитываем границы и padding в общем размере элемента */
}

.gallery-window img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

.slick-dots {
  bottom: 20px; 
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; 
}

.slick-prev {
  left: 10px; 
  z-index: 2;
}

.slick-next {
  right: 10px; 
  z-index: 2;
}

.slick-prev:before,
.slick-next:before {
  color: white; 
  opacity: 0.75;
}

.slick-prev:hover,
.slick-next:hover {
  color: white; 
  opacity: 0.5; 
}


@media (min-width: 801px) {
  .gallery-window {
    width: calc(25% - 2px); /* 4 колонки на больших экранах */
  }
}

@media (max-width: 800px) {
  .gallery-window {
    width: calc(33.333% - 2px); /* 3 колонки на средних экранах */
  }
}

@media (max-width: 600px) {
  .gallery-window {
    width: calc(50% - 2px); /* 2 колонки на маленьких экранах */
  }
}

@media (max-width: 400px) {
  .gallery-window {
    width: 100%; /* 1 колонка на очень маленьких экранах */
  }
}

.slide {
  position: relative;
}

.caption {
  position: absolute;
  bottom: 10px; 
  left: 10px; 
  color: white; 
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 5px; 
  line-height: normal;
}

.modal-image {
    max-width: 100%; 
    max-height: 93vh; 
    object-fit: contain; 
    padding: 0;
    border: none;
    transition: transform 0.2s ease; /* Плавное изменение масштаба */
  }
  .react-responsive-modal-modal {
    position: relative;
    background: transparent; /* Удаление фона */
    box-shadow: none; /* Удаление тени */
    width: auto; /* Или установите фиксированную ширину, если нужно */
    max-width: 90vw; /* Процент от ширины вьюпорта */
    height: auto; /* Автоматически подгоняет высоту под содержимое */
  }
  
  .react-responsive-modal-modalCenter {
    padding: 0; 
  }
  
  
  .react-responsive-modal-closeButton {
    background: white; /* Белый фон за крестиком */
    border-radius: 50%; /* Делаем фон круглым */
    border: none; /* Убираем границу */
    outline: none; /* Убираем контур при фокусе */
  
    /* Позиционирование и размер */
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
  
    /* Стилизация крестика */
    color: black; /* Цвет крестика */
    font-size: 16px; /* Размер крестика */
  }
  
  /* Стили для иконки крестика, если она представлена в виде текста или SVG */
  .react-responsive-modal-closeIcon {
    font-size: 20px; /* Увеличение размера иконки */
  }
  
  .modal-image {
    width: 100%; /* Устанавливает ширину изображения на 100% ширины контейнера */
    height: auto; /* Поддерживает соотношение сторон */
  }
  .react-responsive-modal-overlay {
    background-color: rgba(0, 0, 0, 0.85); /* Измените непрозрачность, чтобы увеличить затемнение */
  }
  
  .modal-button {
    position: absolute; /* Абсолютное позиционирование относительно модального окна */
    right: 25px; /* Отступ справа */
    bottom: 25px; /* Отступ снизу */
  }
  