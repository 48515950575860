.container {
  padding: 0; /* Remove container padding */
}

.card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Add card shadow */
  border-radius: 10px; /* Slightly round card corners */
  border: none; /* Remove default border */
}

.card-body {
  padding: 20px; /* Set card padding */
}

.card-title {
  margin-bottom: 20px; /* Title margin bottom */
  font-size: 1.5em; /* Increase title font size */
}

.card-text {
  font-size: 0.8em; /* Slightly larger text font size */
  color: #333; /* Darker text for readability */
  margin-bottom: 5px; /* Reduced bottom margin */
}

.card-text a {
  color: #0056b3; /* Link color */
  text-decoration: none; /* Remove link text underline */
}

.card-text a:hover {
  text-decoration: underline; /* Underline on hover */
}

.card-text strong {
  font-weight: 600; /* Bold for strong elements */
}

.card-details {
  padding: 15px; /* Column padding */
}

.card-details {
  border-right: 1px solid #ccc; /* Right border for Sample column */
  border-bottom: 1px solid #ccc; /* Bottom border for visual separation */
}

.h4-title {
  margin-top: 20px; /* Section separation margin top */
  font-size: 1.25em; /* Smaller than main title font size */
  color: #bb0000; /* Highlight color */
}

.card-details {
  margin-bottom: 5px; /* Compact item spacing */
}

.abundance-chart {
  padding: 15px; /* Match .sample-details and .dna-details padding */
}

.chart-card {
  border-right: 1px solid #ccc; /* Right border if chart is on the left */
}

.chart-container {
  padding: 15px; /* Chart padding */
}

@media (max-width: 768px) {
  .card-body {
    padding: 15px; /* Mobile device padding */
  }

  .card-title {
    font-size: 1.3em; /* Mobile title font size */
  }

  .card-text {
    font-size: 1em; /* Mobile text font size */
  }
  
  .card-details {
    border-right: none; /* Remove vertical lines for screens < 778px */
    border-bottom: none; /* Adjust as needed for design consistency */
  }
}

/* New media query for screens < 778px */
@media (max-width: 778px) {
  .card-details {
    border-right: none; /* Ensure no vertical lines */
    border-bottom: 1px solid #ccc; /* Optional: keep bottom borders for separation */
  }
  .chart-container{
    padding: 0; /* Remove padding for chart container */
  }
}
